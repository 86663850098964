/*******************************************/
/* Flancos main javascript based on jQuery */
/*******************************************/
+(function($){

	var setListItemBox = function (id, action) {
		$(id).each(function () {
			$this = $(this);
			switch($this.data('status')) {
				case "built":
					if (action == "built") {
						$this.removeClass("hidden").addClass("show");
					} else {
						$this.removeClass("show").addClass("hidden");
					}
				break;
				case "inprogress":
					if (action == "inprogress") {
						$this.removeClass("hidden").addClass("show");
					} else {
						$this.removeClass("show").addClass("hidden");
					}
				break;
				case "other":
					$this.removeClass("show").addClass("hidden");
				break;
			}

		});
	};

	var setInfoListItemBox = function (id, action) {
		$(id).each(function () {
			$this = $(this);
			switch($this.data('status')) {
				case "award":
					if (action == "award") {
						$this.removeClass("faded").addClass("not_faded");
					} else {
						$this.removeClass("not_faded").addClass("faded");
					}
				break;
				case "lecture":
					if (action == "lecture") {
						$this.removeClass("faded").addClass("not_faded");
					} else {
						$this.removeClass("not_faded").addClass("faded");
					}
				break;
				case "publication":
					if (action == "publication") {
						$this.removeClass("faded").addClass("not_faded");
					} else {
						$this.removeClass("not_faded").addClass("faded");
					}
				break;
				case "exhibition":
					if (action == "exhibition") {
						$this.removeClass("faded").addClass("not_faded");
					} else {
						$this.removeClass("not_faded").addClass("faded");
					}
				break;
			}

		});
	};

	/**************************************************************/
	/* Submenu menu selected                                      */
	/**************************************************************/
	(function() {
		$('#submenu').on('click', 'li', function () {
			$this = $(this);
			$("#submenu li").removeClass("active");
			$this.addClass("active");
			switch($this.data('filter')) {
				case "allproject":
					$(".project_box").removeClass("hidden").addClass("show");
				break;
				case "inprogress":
					setListItemBox(".project_box", "inprogress")
				break;
				case "built":
					setListItemBox(".project_box", "built")
				break;
			}
		});
	})();

	/**************************************************************/
	/* Info Submenu menu selected                                      */
	/**************************************************************/
	(function() {
		$('#submenu_info').on('click', 'li', function () {
			$this = $(this);
			$("#submenu_info li").removeClass("active");
			$this.addClass("active");
			switch($this.data('filter')) {
				case "allinfo":
					$(".info_box").removeClass("faded").addClass("not_faded");
				break;
				case "award":
					setInfoListItemBox(".info_box", "award")
				break;
				case "lecture":
					setInfoListItemBox(".info_box", "lecture")
				break;
				case "publication":
					setInfoListItemBox(".info_box", "publication")
				break;
				case "exhibition":
					setInfoListItemBox(".info_box", "exhibition")
				break;
			}
		});
	})();

	/**************************************************************/
	/* On document load                                           */
	/**************************************************************/
	$(function () {
		if ($('#submenu').length) {
			$('#submenu')
				.hide()
				.slideDown("slow");
		} else if ($('#submenu_static').length) {
			$('#submenu_static')
				.hide()
				.slideDown("slow");
		} 
	});


})(jQuery);
